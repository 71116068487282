import api from './_client';

// Types
import { Civility, Site, TransactionValidationStatus, User, UserRole, UserType } from './apiTypes';
import { Address } from 'cluster';

// Controller Interface
export interface UserCreatePayload {
    userType: UserType;
    civility: Civility;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    phoneCallingCode: string;
    address: Address;
    addresses: Address[];
    defaultAddress: Address;
    companyName?: string;
    password: string;
    oldPassword: string;
    siret?: string;
    vatCode?: string;
    textArea?: string;
    role?: UserRole;
    goToken?: string;
    fbId?: string;
    fbToken?: string;
}

export interface UserUpdatePayload {
    id: User['id'];
    body: UserCreatePayload;
}

export interface UserVerificationPayload {
    token: string;
}

export interface UserTransactionPayload {
    id: string;
    siteId: Site['id'];
}

export interface UserTransactionListPayload {
    validationStatus: TransactionValidationStatus;
}

export interface UserIdPayload {
    id: User['id'];
    siteId: Site['id'];
}

export const create = async (payload: UserCreatePayload) => {
    return await api.post(`/ppo/users`, payload);
};

export const update = async (payload: UserUpdatePayload) => {
    return await api.post(`/ppo/me`, payload.body);
};

export const verification = async (payload: UserVerificationPayload) => {
    return await api.post(`/signedAction?token=${payload.token}`);
};

export const titles = async (payload: UserTransactionPayload) => {
    return await api.get(`/ppo/listsubscribes/${payload.siteId}`);
};

export const title = async (payload: UserTransactionPayload) => {
    return await api.get(`/ppo/detailsubscribe/${payload.siteId}/${payload.id}`);
};

export const transaction = async (payload: UserTransactionPayload) => {
    return await api.get(`/ppo/transactions/${payload.id}`);
};

export const transactionList = async (payload: UserTransactionListPayload) => {
    return await api.get(`/ppo/transactions/`, { params: payload });
};

export const receipt = async (payload: UserTransactionPayload) => {
    return await api.get(`/transactions/receipt/${payload.id}`, { responseType: 'blob' });
};
