import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Radio, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import React, { FC, useContext } from 'react';
import { SiteContext } from '../../context/SiteContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Breakpoint } from 'react-socks';
import Input from '../../components/form/Input';
import formMessages from '../../locale/formMessages';
import { getUser } from '../../store/actions/auth';
import { Civility } from '../../store/api/apiTypes';
import messages from './messages';

interface UserInfoFormProps {
    form: FormInstance;
    index: number;
    disabled?: boolean;
}

const UserInfoForm: FC<UserInfoFormProps> = ({ form, index, disabled }) => {
    const { formatMessage } = useIntl();
    const user = useSelector(getUser);
    const { site } = useContext(SiteContext);
    const useUserInfos = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const users = form.getFieldValue('users');

        if (users?.[index]) {
            users[index].civility = user?.civility;
            users[index].firstName = user?.firstName;
            users[index].lastName = user?.lastName;
            form.setFieldsValue({
                users,
            });
        }
    };

    return (
        <div className="users-form">
            <Breakpoint lg up>
                <FormattedMessage {...messages.are_you_the_user} />{' '}
                <a href="#use-my-infos" onClick={useUserInfos}>
                    <FormattedMessage {...messages.use_your_infos} />
                </a>
            </Breakpoint>

            <Row gutter={15} className="row-column-mobile">
                <Col>
                    <Form.Item
                        shouldUpdate
                        name={['users', index, 'civility']}
                        rules={[
                            {
                                required: true,
                                message: formatMessage(formMessages.error_required),
                            },
                        ]}
                    >
                        <Radio.Group buttonStyle="solid" size="large" disabled={disabled}>
                            <Radio.Button value={Civility.missus}>
                                <FormattedMessage {...formMessages.civility_mme} />
                            </Radio.Button>
                            <Radio.Button value={Civility.mister}>
                                <FormattedMessage {...formMessages.civility_mr} />
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col flex="auto">
                    <Row gutter={15}>
                        <Col span={12}>
                            <Form.Item
                                shouldUpdate
                                initialValue={''}
                                name={['users', index, 'firstName']}
                                rules={[
                                    {
                                        required: true,
                                        message: formatMessage(formMessages.error_required),
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={15}
                                    placeholder={formatMessage(formMessages.firstname_label_offer)}
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                shouldUpdate
                                initialValue={''}
                                name={['users', index, 'lastName']}
                                rules={[
                                    {
                                        required: true,
                                        message: formatMessage(formMessages.error_required),
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={20}
                                    placeholder={formatMessage(formMessages.lastname_label_offer)}
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {!!site?.nbPlates && (
                <p className="text-left">
                    {site?.nbPlates > 1 ? (
                        <FormattedMessage {...messages.your_vehicles} values={{ count: site?.nbPlates }} />
                    ) : (
                        <FormattedMessage {...messages.your_vehicle} />
                    )}
                </p>
            )}
            {!!site?.nbPlates && (
                <div className="registrations ">
                    <Form.List name={['users', index, 'licensePlates']} initialValue={[{ code: '' }]}>
                        {(fields, { add, remove }) => {
                            return (
                                <div>
                                    {fields.map((field, index) => (
                                        <div key={field.key} className="registration-row">
                                            <Form.Item
                                                name={[index, 'code']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        pattern: /^[a-z\-A-Z0-9]{1,10}$/,
                                                        message: formatMessage(formMessages.error_plate),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={formatMessage(formMessages.registration_label)}
                                                    disabled={disabled}
                                                />
                                            </Form.Item>
                                            {index < fields.length && fields.length > 1 && (
                                                <Button
                                                    type="ghost"
                                                    shape="circle"
                                                    size="large"
                                                    icon={<MinusOutlined />}
                                                    className="btn-circle-icon"
                                                    onClick={() => {
                                                        remove(index);
                                                    }}
                                                    disabled={disabled}
                                                />
                                            )}

                                            {fields.length === index + 1 &&
                                                !!site?.nbPlates &&
                                                site?.nbPlates > fields.length && (
                                                    <Button
                                                        type="ghost"
                                                        shape="circle"
                                                        size="large"
                                                        icon={<PlusOutlined />}
                                                        className="btn-circle-icon"
                                                        onClick={() => {
                                                            add();
                                                        }}
                                                        disabled={disabled}
                                                    />
                                                )}
                                        </div>
                                    ))}
                                </div>
                            );
                        }}
                    </Form.List>
                </div>
            )}
        </div>
    );
};

export default UserInfoForm;
