import { defineMessages } from 'react-intl';

export default defineMessages({
    error_default: {
        id: 'register.error_default',
        defaultMessage: 'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur',
    },
    error_email_already_used: {
        id: 'register.error_email_already_used',
        defaultMessage: "L'email indiqué est déjà utilisé",
    },
    title: {
        id: 'register.title',
        defaultMessage: 'Inscription',
    },
    title_social: {
        id: 'register.title_social',
        defaultMessage: 'Connection',
    },
    already_account: {
        id: 'register.already_account',
        defaultMessage: 'Vous avez déjà un compte ?',
    },
    login: {
        id: 'register.login',
        defaultMessage: 'Connectez-vous',
    },
    your_infos: {
        id: 'register.your_infos',
        defaultMessage: 'Vos informations',
    },
    your_address: {
        id: 'register.your_address',
        defaultMessage: 'Votre adresse',
    },
    company: {
        id: 'register.company',
        defaultMessage: 'Société',
    },
    register_cta: {
        id: 'register.register_cta',
        defaultMessage: 'Créer un compte',
    },
    rgpd_legals: {
        id: 'register.rgpd_legals',
        defaultMessage: 'En cliquant sur Créer un compte, je déclare avoir lu et accepter les {cgu} et la {privacy}.',
    },
    rgpd_legals_cgu: {
        id: 'register.rgpd_legals_cgu',
        defaultMessage: 'Conditions d’utilisation',
    },
    rgpd_legals_privacy: {
        id: 'register.rgpd_legals_privacy',
        defaultMessage: 'Politique de confidentialité',
    },
    success_text: {
        id: 'register.success_text',
        defaultMessage:
            "Nous venons d'envoyer à l'adresse e-mail {email} un email contenant un lien afin de valider votre inscription.",
    },
    success_text_social: {
        id: 'register.success_text_social',
        defaultMessage: 'Votre compte a bien été créé.',
    },
});
