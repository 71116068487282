import React, { FC, useEffect } from 'react';
import { Alert, Button, Form, FormProps, message, Switch, Typography } from 'antd';
import Seo from '../../components/Seo';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Input from '../../components/form/Input';
import formMessages from '../../locale/formMessages';
import TextArea from 'antd/lib/input/TextArea';
import { getContactState, contact as contactAction } from '../../store/actions/sites';
import { useActions, usePrevious } from '../../hooks';

import '../../assets/styles/Contact.less';
import { useSelector } from 'react-redux';

const Contact: FC = () => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [contact] = useActions([contactAction.trigger]);
    const contactState = useSelector(getContactState);
    const previous = usePrevious({
        contactState,
    });

    useEffect(() => {
        if (previous?.contactState.loading && !contactState.loading) {
            if (contactState.success) {
                form.resetFields();
                message.success(formatMessage(formMessages.contact_success));
            } else {
                message.error(formatMessage(formMessages.error_default));
            }
        }
    }, [previous, contactState, formatMessage, form]);

    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        contact(values);
    };

    const error = undefined;

    return (
        <>
            <Seo title="Contact" />
            <Form
                form={form}
                onFinish={onFormValidSubmit}
                className="contact-form"
                layout="vertical"
                requiredMark={false}
            >
                <div className="wrapper small">
                    <div className="header">
                        <Typography.Title level={1} className="title title-1">
                            <FormattedMessage {...messages.title} />
                        </Typography.Title>
                        <Typography.Paragraph>
                            <FormattedMessage {...messages.intro} />
                        </Typography.Paragraph>
                    </div>

                    <div className="card mt-2">
                        <Form.Item
                            name="firstName"
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <Input placeholder={formatMessage(formMessages.firstname_label)} />
                        </Form.Item>
                        <Form.Item
                            name="lastName"
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <Input placeholder={formatMessage(formMessages.lastname_label)} />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: formatMessage(formMessages.email_error) }]}
                        >
                            <Input placeholder={formatMessage(formMessages.email_label)} />
                        </Form.Item>
                        <Form.Item
                            label={formatMessage(formMessages.message_label)}
                            name="message"
                            rules={[{ required: true, message: formatMessage(formMessages.email_error) }]}
                        >
                            <TextArea />
                        </Form.Item>
                        <div className="switch-row">
                            <Form.Item name="send_cc" rules={[{ required: false }]} className="mb-0">
                                <Switch className="mb-0" />
                            </Form.Item>
                            <div className="label">
                                <FormattedMessage {...messages.copy_message} />
                            </div>
                        </div>
                        <div className="flex-column-center lg-flex-row cta-container mt-2">
                            <Form.Item className="mb-0">
                                {error ? (
                                    <div className="login-error-message">
                                        <Alert type="error" message={error} showIcon />
                                    </div>
                                ) : null}

                                <Button type="primary" shape="round" size="large" block htmlType="submit">
                                    <FormattedMessage {...messages.submit} />
                                </Button>
                            </Form.Item>

                            <div className="all-required font-title">
                                <FormattedMessage {...formMessages.all_fields_required} />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default Contact;
