import formMessages from '../../locale/formMessages';
import genericMessages from '../../locale/genericMessages';

export interface Organization {
    id: string;
    name: string;
}

export interface User {
    id: string;
    userType: UserType;
    companyName: string;
    civility: Civility;
    email: string;
    firstName: string;
    lastName: string;
    siret: string;
    vatCode: string;
    textArea: string;
    role?: UserRole;
    site: Site;
    organization?: any;
    addresses: Address[];
    defaultAddress: Address;
    goToken?: string;
    fbId?: string;
}

export interface Address {
    userType: UserType;
    companyName: string;
    firstName: string;
    lastName: string;
    civility: Civility;
    street: string;
    complement: string;
    zipCode: string;
    city: string;
    country: string;
    default: boolean;
}

export interface Site {
    id: string;
    name: string;
    phone?: string;
    apimKey?: string;
    service?: string;
    siteCode?: number;
    login?: string;
    password?: string;
    siteId?: string;
    currencyIso?: string;
    currencyCentsDigits?: number;
    deviceId?: number;
    parcId?: number;
    deviceName?: string;
    createdAt?: Date;
    updatedAt?: Date;
    enabled: boolean;
    themeColor?: string;
    logoImage?: string;
    bannerImage?: string;
    loginImage?: string;
    pages: Page[];
    nbPlates?: number;
    badgeType?: BadgeType;
    deadline?: boolean;
    psp: Psp;
}

export enum PSPName {
    payline = 'payline',
    nmi = 'nmi',
    advam = 'advam',
    globalPayment = 'globalPayment',
}
export interface Psp {
    name: PSPName;
}

export interface Page {
    id: string;
    slug: string;
    title: string;
    content: string;
}

export enum SupportedLanguage {
    fr = 'fr',
    en = 'en',
    es = 'es',
}

export const LanguagesList: SupportedLanguage[] = [SupportedLanguage.fr, SupportedLanguage.en];

export type Translation = {
    [key in SupportedLanguage]?: string;
};

export interface Parking {
    id: number;
    name: string;
    address?: string;
    zipCode?: string;
    city?: string;
    geoCoordinate?: GeoCoordinate;
    nbSpaces?: number;
    phoneNumber?: string;
}

export interface GeoCoordinate {
    latitude?: string;
    longitude?: string;
    altitude?: string;
}

export interface Offer {
    offerId: string;
    name: string;
    beginValidityDate?: string;
    endValidityDate?: string;
    isPromotional?: boolean;
    offerName?: string;
    offerType?: string;
    periodTypes?: PeriodType[];
    sellingPrice?: SellingPrice;
    months?: number;
    aboPriceByMonth?: number;
    vehicleClass?: VehicleType;
    zones?: Zone[];
    currencyIso?: string;
}

export interface Zone {
    parkingId: Parking['id'];
    parkingName: string;
    zoneName: string;
}

export interface SellingPrice {
    dutiesAmount: DutiesAmount[];
    dutyFreeAmount: number;
    totalDueAmount: number;
}

export interface DutiesAmount {
    name?: string;
    amount: number;
    rate: number;
}

export interface OfferPrice {
    id: string;
    name: string;
    beginValidityDate?: string;
    endValidityDate?: string;
    periodType: PeriodType;
    price: SellingPrice;
    periods: OfferPeriod[];
}

export interface OfferPeriod {
    beginValidityDate?: string;
    endValidityDate?: string;
    price: SellingPrice;
}

export interface RenewOfferPrice {
    offerPrice?: OfferPrice;
}

export interface AuthResponse {
    user: User;
    authToken: string;
    refreshToken: string;
}

export interface SocialAuthResponse {
    goToken?: string;
    fbId?: string;
    fbToken?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export interface UserInfo {
    title?: Civility;
    civility?: Civility;
    name?: string;
    firstName?: string;
    lastName?: string;
    licensePlates?: string[];
    isCompany?: boolean;
}

export interface Title {
    id?: string;
    titleId?: string;
    offerPrice?: OfferPrice;
    offerSale: any;
    handler?: UserInfo;
    licensePlates?: string[];
    withBarcode?: boolean;
    barcode?: string;
    canRenew?: boolean;
    beginRenewDate?: Date;
    endRenewDate?: Date;
    offer?: Offer;
    offerId?: string;
    titleNumber?: string;
    transaction?: Transaction;
    offerType?: string;
    info?: TitleInfo;
    beginValidityDate?: Date;
    endValidityDate?: Date;
}

export interface TitleInfo {
    handler?: UserInfo;
    licensePlates?: string[];
    offerPrice?: OfferPrice;
}

export interface Transaction {
    id?: string;
    account?: User;
    badgeType?: BadgeType;
    createdAt?: Date;
    paymentStatus?: string;
    paymentToken?: string;
    site?: Site;
    titles?: Title[];
    totalDueAmount?: number;
    deadline?: boolean;
    billingAddress?: Address;
    deliveryAddress?: Address;
    psp?: PSPName;
    globalPaymentTransactionResult?: { [key: string]: any };
    validationStatus?: TransactionValidationStatus;
    validationComment?: string;
    offer?: Offer;
    currencyIso?: string;
    currencyCentsDigits?: number;
    returnUrl?: string;
}

// ------------------------------------------
// ENUMs

export enum TransactionValidationStatus {
    pending = 'pending',
    refused = 'refused',
    accepted = 'accepted',
    toCancel = 'toCancel',
    canceled = 'canceled',
}

export enum UserRole {
    customer = 'customer',
}

export enum VehicleType {
    all = 'All',
    car = 'Car',
    motorcycle = 'Motorcycle',
    small = 'SmallVehicle',
    heavy = 'HeavyVehicle',
}

export const vehicleTypeMessages = new Map([
    [VehicleType.all, genericMessages.vehicle_type_all],
    [VehicleType.car, genericMessages.vehicle_type_car],
    [VehicleType.motorcycle, genericMessages.vehicle_type_motorcycle],
    [VehicleType.small, genericMessages.vehicle_type_small],
    [VehicleType.heavy, genericMessages.vehicle_type_heavy],
]);

export enum Civility {
    mister = 'mr',
    missus = 'mrs',
}

export const civilityMessages = new Map([
    [Civility.mister, formMessages.civility_mr],
    [Civility.missus, formMessages.civility_mme],
]);

export enum UserType {
    individual = 'individual',
    company = 'company',
}

export const userTypeMessages = new Map([
    [UserType.individual, formMessages.user_type_individual],
    [UserType.company, formMessages.user_type_company],
]);

export enum PeriodType {
    startNextMonth = 'StartNextMonth',
    startCurrentMonth = 'StartCurrentMonth',
    endOfCurrentMonth = 'EndOfCurrentMonth',
    endOfCurrentMonthThenNextPeriod = 'EndOfCurrentMonthThenNextPeriod',
    fixedDate = 'FixedDate',
}

export const PeriodTypeMessages = new Map([
    [PeriodType.startNextMonth, formMessages.period_type_startNextMonth],
    [PeriodType.startCurrentMonth, formMessages.period_type_startCurrentMonth],
    [PeriodType.endOfCurrentMonth, formMessages.period_type_endOfCurrentMonth],
    [PeriodType.endOfCurrentMonthThenNextPeriod, formMessages.period_type_endOfCurrentMonthThenNextPeriod],
    [PeriodType.fixedDate, formMessages.period_type_fixedDate],
]);

export enum AddressType {
    delivery = 'delivery',
    billing = 'billing',
}

export enum BadgeType {
    dematerialized = 'Dematerialized',
    physical = 'Physical',
    dematerializedAndPhysical = 'DematerializedAndPhysical',
}

export const BadgeTypeMessages = new Map([
    [BadgeType.dematerialized, formMessages.badge_type_dematerialized_label],
    [BadgeType.physical, formMessages.badge_type_physical_label],
    [BadgeType.dematerializedAndPhysical, formMessages.badge_type_both_label],
]);
