import React, { FC, useContext } from 'react';
import { Card, Typography } from 'antd';
import Seo from '../../components/Seo';
import { Redirect, useParams } from 'react-router-dom';
import { SiteContext } from '../../context/SiteContext';
import { getRoute, RoutePathName } from '../../routes';

const Legals: FC = () => {
    const { site } = useContext(SiteContext);
    const { slug } = useParams<{ slug: string }>();

    const page = site?.pages.find((page) => page.slug === slug);
    if (!page) {
        return <Redirect to={getRoute(RoutePathName.notFound)} />;
    }

    const pageTitle = page.title;
    const pageContent = page.content;

    return (
        <>
            <Seo title={pageTitle} />
            <div className="wrapper mt-3 mb-3">
                <div className="header text-center mb-2">
                    <Typography.Title level={1} className="title title-1">
                        {pageTitle}
                    </Typography.Title>
                </div>

                <Card>{pageContent && <div dangerouslySetInnerHTML={{ __html: pageContent }} />}</Card>
            </div>
        </>
    );
};

export default Legals;
