import React, { FC, useEffect, useState } from 'react';
import { Alert, Button, Divider, Form, FormProps, message, Radio, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import accountMessages from '../../../locale/accountMessages';
import Seo from '../../../components/Seo';
import Input from '../../../components/form/Input';
import formMessages from '../../../locale/formMessages';
import { Breakpoint } from 'react-socks';
import { update as updateAction, getUsersUpdateState } from '../../../store/actions/users';
import { useActions, usePrevious } from '../../../hooks';
import { useSelector } from 'react-redux';
import { Civility, UserType } from '../../../store/api/apiTypes';
import { UserCreatePayload } from '../../../store/api/users';
import { getAuthState, checkLoginStatus as checkLoginStatusAction } from '../../../store/actions/auth';
import registerMessages from '../../register/messages';

const Informations: FC = () => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [updateUser, checkLoginStatus] = useActions([updateAction.trigger, checkLoginStatusAction.trigger]);
    const userUpdateState = useSelector(getUsersUpdateState);
    const authState = useSelector(getAuthState);
    const [formValues, setFormValues] = useState<any>();
    const [userId, setUserId] = useState<string>();

    const previous = usePrevious({
        userUpdateState,
    });

    useEffect(() => {
        form.setFieldsValue(authState.user);
        setUserId(authState.user?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFormValidSubmit: FormProps['onFinish'] = (user: UserCreatePayload) => {
        updateUser({
            id: userId,
            body: user,
        });
    };

    const onFormValueChange: FormProps['onValuesChange'] = (changes, values) => {
        setFormValues(values);
    };

    useEffect(() => {
        if (previous?.userUpdateState.loading && !userUpdateState.loading) {
            if (userUpdateState.success) {
                message.success(formatMessage(formMessages.update_success));
                checkLoginStatus();
            }
        }
    }, [previous, userUpdateState, formatMessage, checkLoginStatus]);

    let error = userUpdateState.error ? formatMessage(formMessages.error_default) : null;

    if (userUpdateState.error?.data?.error_code) {
        switch (userUpdateState.error?.data?.error_code) {
            case 'email_already_used':
                error = formatMessage(registerMessages.error_email_already_used);
                break;
        }
    }

    return (
        <>
            <Seo title={formatMessage(accountMessages.your_informations)} />
            <Form
                form={form}
                onValuesChange={onFormValueChange}
                onFinish={onFormValidSubmit}
                className="informations-form"
                layout="vertical"
                requiredMark={false}
            >
                <div className="header mb-2">
                    <Typography.Title level={1} className="title title-1">
                        <FormattedMessage {...accountMessages.your_informations} />
                    </Typography.Title>
                </div>

                <Form.Item
                    label={formatMessage(formMessages.user_type_label)}
                    className="radio-inline-label"
                    name="userType"
                    rules={[{ required: true, message: formatMessage(formMessages.email_error) }]}
                >
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value={UserType.individual}>
                            <FormattedMessage {...formMessages.user_type_individual} />
                        </Radio.Button>
                        <Radio.Button value={UserType.company}>
                            <FormattedMessage {...formMessages.user_type_company} />
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label={formatMessage(formMessages.civility_label)}
                    className="radio-inline-label"
                    name="civility"
                    rules={[{ required: true, message: formatMessage(formMessages.email_error) }]}
                >
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value={Civility.missus}>
                            <FormattedMessage {...formMessages.civility_mme} />
                        </Radio.Button>
                        <Radio.Button value={Civility.mister}>
                            <FormattedMessage {...formMessages.civility_mr} />
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="firstName"
                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                >
                    <Input placeholder={formatMessage(formMessages.firstname_label)} />
                </Form.Item>
                <Form.Item
                    name="lastName"
                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                >
                    <Input placeholder={formatMessage(formMessages.lastname_label)} />
                </Form.Item>
                <Form.Item name="email" rules={[{ required: true, message: formatMessage(formMessages.email_error) }]}>
                    <Input placeholder={formatMessage(formMessages.email_label)} />
                </Form.Item>
                <Form.Item
                    name="phone"
                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                >
                    <Input placeholder={formatMessage(formMessages.phone_label)} />
                </Form.Item>

                {formValues && formValues.userType === UserType.company && (
                    <>
                        <Divider orientation="left">
                            <FormattedMessage {...formMessages.user_type_company} />
                        </Divider>

                        <Form.Item
                            name={['companyName']}
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <Input placeholder={formatMessage(formMessages.company_name_label)} />
                        </Form.Item>

                        <Form.Item
                            name={['siret']}
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <Input placeholder={formatMessage(formMessages.company_siret_label)} />
                        </Form.Item>

                        <Form.Item
                            name={['vatCode']}
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <Input placeholder={formatMessage(formMessages.company_vat_label)} />
                        </Form.Item>

                        <Form.Item name={['textArea']}>
                            <Input placeholder={formatMessage(formMessages.company_info_label)} />
                        </Form.Item>
                    </>
                )}

                {error ? (
                    <div className="login-error-message">
                        <Alert type="error" message={error} showIcon />
                    </div>
                ) : null}

                <div className="flex-column-center lg-flex-row cta-container mt-2">
                    <Form.Item className="mb-0">
                        <Button type="primary" shape="round" size="large" block htmlType="submit" className="btn-xl">
                            <Breakpoint lg down>
                                <FormattedMessage {...formMessages.cta_update} />
                            </Breakpoint>
                            <Breakpoint xl up>
                                <FormattedMessage {...accountMessages.cta_update_infos} />
                            </Breakpoint>
                        </Button>
                    </Form.Item>

                    <div className="all-required font-title">
                        {formValues && formValues.userType === UserType.company ? (
                            <FormattedMessage {...formMessages.all_fields_required_except} />
                        ) : (
                            <FormattedMessage {...formMessages.all_fields_required} />
                        )}
                    </div>
                </div>
            </Form>
        </>
    );
};

export default Informations;
