interface Country {
    country: string;
    calling_code: string;
}

interface CountryAbbreviation {
    country: string;
    abbreviation: string;
}

export function getCountryOptions() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const countries = require('../assets/country-by-abbreviation.json');
    return countries.map((item: CountryAbbreviation, index: number) => {
        return {
            key: 'country-' + index.toString(),
            label: item.country,
            value: item.abbreviation,
        };
    });
}

export function getCountryAbbreviationFromCountryName(name: string) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const countries: CountryAbbreviation[] = require('../assets/country-by-abbreviation.json');
    return countries.find((e: CountryAbbreviation) => e.country === name)?.abbreviation;
}

export function getCountryPhoneCodeOptions() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const countries = require('country-json/src/country-by-calling-code.json');
    return countries.map((item: Country, index: number) => {
        return {
            key: 'calling-code-' + index.toString(),
            label: item.country + ' (+' + item.calling_code + ')',
            value: `${item.country}_${item.calling_code}`,
        };
    });
}
