import React, { FC, useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Divider, Form, FormProps, Row, Spin, Switch } from 'antd';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import accountMessages from '../../../../locale/accountMessages';
import Seo from '../../../../components/Seo';
import VehicleIcon from '../../../../components/VehicleIcon';
import IconMarker from '../../../../components/icons/IconMarker';
import { CalendarOutlined } from '@ant-design/icons';
import IconArrowTime from '../../../../components/icons/IconArrowTime';
import formMessages from '../../../../locale/formMessages';
import paymemtMessages from '../../../../locale/paymentMessages';
import { useHistory, useParams } from 'react-router-dom';
import { title as getTitleAction, getTitleState } from '../../../../store/actions/users';
import {
    renewPrice as getRenewPriceAction,
    getOffersRenewPriceState,
    renewTitle as getRenewTitleAction,
    getOffersRenewTitleState,
    authNmiFailure as authNmiFailureAction,
    authNmiReNewCompleted as authNmiCompletedAction,
    authNmiError as authNmiErrorAction,
    getOffersAuthNmiReNewCompletedState,
} from '../../../../store/actions/offers';
import { useSelector } from 'react-redux';
import { useActions, usePrevious } from '../../../../hooks';
import { SiteContext } from '../../../../context/SiteContext';
import Price from '../../../../components/Price';
import { BadgeTypeMessages, PSPName } from '../../../../store/api/apiTypes';
import { getDomain, getRoute, RoutePathName } from '../../../../routes';
import { getUser } from '../../../../store/actions/auth';
import Modal3DSecure from '../../../payment/Modal3DSecure';
import genericMessages from '../../../../locale/genericMessages';

declare global {
    interface Window {
        CollectJS?: any;
    }
}

let threeDSecureInterface: any;

const RenewSubscription: FC = () => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [form] = Form.useForm();
    const { siteId, site, transaction, setTransaction } = useContext(SiteContext);
    const user = useSelector(getUser);
    const authNmiState = useSelector(getOffersAuthNmiReNewCompletedState);
    const { titleId } = useParams<{ titleId: string }>();
    const [authNmiCompleted, authNmiFailure, authNmiError] = useActions([
        authNmiCompletedAction.trigger,
        authNmiFailureAction.trigger,
        authNmiErrorAction.trigger,
    ]);
    const [getTitle, renewTitle] = useActions([getTitleAction.trigger, getRenewTitleAction.trigger]);
    const titleState = useSelector(getTitleState);
    const renewTitleState = useSelector(getOffersRenewTitleState);
    const [getRenewPrice] = useActions([getRenewPriceAction.trigger]);
    const renewPriceState = useSelector(getOffersRenewPriceState);
    const [modal3DSecureVisible, setModal3DSecureVisible] = useState<boolean>(false);
    const [error, setError] = useState<any>();
    const [isWaitingForPayment, setIsWaitingForPayment] = useState(false);
    const title = titleState?.data;
    const renewPrice = renewPriceState?.data?.offerPrice;

    const previous = usePrevious({
        renewTitleState,
        authNmiState,
    });
    const close3DSecureModal = () => {
        threeDSecureInterface?.unmount();
        setIsWaitingForPayment(false);
        setModal3DSecureVisible(false);
    };
    useEffect(() => {
        if (titleId) {
            getTitle({ id: titleId, siteId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titleId, getTitle]);

    useEffect(() => {
        if (title?.titleId) {
            getRenewPrice({
                siteId,
                titleId: title.titleId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title]);

    const totalPrice = renewPrice ? renewPrice.price.totalDueAmount : undefined;
    const totalPriceDutyFree = renewPrice ? renewPrice.price.dutyFreeAmount : undefined;

    const onFormFinish: FormProps['onFinish'] = () => {
        setError(undefined);
        renewTitle({
            siteId,
            returnURL: encodeURI(getDomain() + getRoute(RoutePathName.paymentConfirm)),
            cancelURL: encodeURI(getDomain() + getRoute(RoutePathName.paymentCancel)),
            offerPrice: renewPrice,
            titleId: title?.titleId,
        });
    };

    useEffect(() => {
        if (totalPrice && transaction && title && site?.psp.name === PSPName.nmi) {
            window.CollectJS.configure({
                variant: 'lightbox',
                instructionText: formatMessage(paymemtMessages.instructionPaymentNMI),
                buttonText: formatMessage(paymemtMessages.buttonPaymentNMI),
                callback: (e: any) => {
                    setIsWaitingForPayment(true);
                    const options = {
                        paymentToken: e.token,
                        currency: site?.currencyIso,
                        amount: totalPrice / Math.pow(10, 2),
                        email: user?.email,
                        city: title?.transaction?.billingAddress?.city,
                        address1: title?.transaction?.billingAddress?.street,
                        country: title?.transaction?.billingAddress?.country,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        postalCode: title?.transaction?.billingAddress?.zipCode,
                    };
                    threeDSecureInterface?.unmount();
                    const gateway = window.Gateway.create('checkout_public_Nv4Vcu45Rjj6e2485srH5BP35R26es8W');
                    const threeDS = gateway.get3DSecure();
                    threeDSecureInterface = threeDS.createUI(options);
                    threeDSecureInterface.start('#threeDSMountPoint');
                    gateway.on('error', (e: any) => {
                        console.error('gateway', e);
                        authNmiError({
                            siteId,
                            e,
                        });
                        setError(genericMessages.error_payment);
                        close3DSecureModal();
                    });
                    threeDS.on('error', (e: any) => {
                        console.error('threeDS', e);
                        authNmiError({
                            siteId,
                            e,
                        });
                        setError(genericMessages.error_payment);
                        close3DSecureModal();
                    });
                    threeDSecureInterface.on('complete', function (res: any) {
                        console.log('complete', res);
                        const body = {
                            card: e.card,
                            ...res,
                            xid: res.xid,
                            cavv: res.cavv,
                            eci: res.eci,
                            cardHolderAuth: res.cardHolderAuth,
                            directoryServerId: res.directoryServerId,
                            threeDsVersion: res.threeDsVersion,
                            paymentToken: options.paymentToken,
                            currency: options.currency,
                            amount: totalPrice,
                            transactionId: transaction?.id,
                        };
                        authNmiCompleted({
                            siteId,
                            body,
                        });
                        close3DSecureModal();
                    });

                    threeDSecureInterface.on('failure', function (res: any) {
                        console.log('failure', res);
                        if (res.code === 'TRANSACTION_STATUS_N') {
                            setError(paymemtMessages.failure_paymentNMI_N);
                        } else if (res.code === 'TRANSACTION_STATUS_U') {
                            setError(paymemtMessages.failure_paymentNMI_U);
                        } else if (res.code === 'TRANSACTION_STATUS_R') {
                            setError(paymemtMessages.failure_paymentNMI_R);
                        } else {
                            setError(paymemtMessages.failure_paymentNMI);
                        }
                        const body = {
                            ...res,
                            transactionId: transaction?.id,
                        };
                        authNmiFailure({
                            siteId,
                            body,
                        });
                        close3DSecureModal();
                    });
                    threeDSecureInterface.on('error', (res: any) => {
                        console.error('error', res);
                        const body = {
                            ...res,
                            transactionId: transaction?.id,
                        };
                        setError(genericMessages.error_payment);
                        authNmiError({
                            siteId,
                            body,
                        });
                        close3DSecureModal();
                    });
                    threeDSecureInterface.on('challenge', (res: any) => {
                        console.log('challenge', res);
                        setModal3DSecureVisible(true);
                    });
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPrice, site, title, transaction]);

    useEffect(() => {
        if (previous?.renewTitleState.loading && !renewTitleState.loading) {
            if (renewTitleState.success && renewTitleState.data) {
                setTransaction(renewTitleState.data);
                if (site?.psp.name === PSPName.payline) {
                    history.push(getRoute(RoutePathName.payment));
                } else if (site?.psp.name === PSPName.nmi) {
                    window.CollectJS.startPaymentRequest();
                } else {
                    setError(genericMessages.error_not_available);
                }
            }
        }
    }, [previous, renewTitleState, setTransaction, history, site]);

    useEffect(() => {
        if (previous?.authNmiState.loading && !authNmiState.loading) {
            if (authNmiState.success && authNmiState.data) {
                setTransaction(authNmiState.data);
                history.push(getRoute(RoutePathName.paymentConfirm), { transactionId: authNmiState.data.id });
            } else {
                setError(genericMessages.error_not_available);
            }
        }
    }, [previous, authNmiState, history, setTransaction]);

    useEffect(() => {
        return () => {
            threeDSecureInterface?.unmount();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Seo title={formatMessage(accountMessages.renew)} />

            {titleState.loading ? (
                <Spin spinning={true} />
            ) : (
                <div id="renew-subscription" className="wrapper mt-3 mb-3">
                    <div className="header mb-2">
                        <h1 className="title title-1 mb-1">
                            <FormattedMessage {...accountMessages.new_sub_title} />
                        </h1>

                        <p>
                            <FormattedMessage {...accountMessages.new_sub_intro} />
                        </p>
                    </div>

                    <Form form={form} onFinish={onFormFinish} requiredMark={false} colon={false} layout="vertical">
                        <div className="card payment-summary">
                            <div className="price-row offer-summary-container">
                                <div className="price-label offer-summary-alt">
                                    <VehicleIcon type={title?.offer?.vehicleClass} />
                                    <div className="summary">
                                        <div className="name">{title?.offer?.offerName}</div>
                                        <div className="details">
                                            <div className="parking">
                                                <IconMarker className="text-primary" />
                                                &nbsp;
                                                {title?.offer?.zones?.map((zone, index) => (
                                                    <span
                                                        className="parking-name"
                                                        key={'offer-zone-' + index.toString()}
                                                    >
                                                        {zone.parkingName} - {zone.zoneName}
                                                    </span>
                                                ))}
                                            </div>
                                            <div className="dates">
                                                <CalendarOutlined className="text-primary" />
                                                &nbsp; <FormattedDate value={renewPrice?.beginValidityDate} />{' '}
                                                <IconArrowTime /> <FormattedDate value={renewPrice?.endValidityDate} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="price">
                                    <div className="label">
                                        <FormattedMessage {...formMessages.total_to_pay} />
                                    </div>
                                    <div className="value text-primary">
                                        <Price value={renewPrice?.price.totalDueAmount} />
                                    </div>
                                </div>
                            </div>

                            <Divider />

                            <Row gutter={[15, 15]}>
                                <Col xs={24} md={12}>
                                    <h1 className="title title-1 smaller mb-1">
                                        <FormattedMessage {...formMessages.configuration} />
                                    </h1>
                                    {title?.transaction?.badgeType && (
                                        <div className="info-row">
                                            <span className="label text-primary">
                                                <FormattedMessage {...formMessages.badge_label} />
                                            </span>
                                            &nbsp;
                                            <FormattedMessage
                                                {...BadgeTypeMessages.get(title?.transaction?.badgeType)}
                                            />
                                        </div>
                                    )}
                                    <div className="info-row">
                                        <span className="label text-primary">
                                            <FormattedMessage {...formMessages.end_notification_label} />
                                        </span>
                                        &nbsp;
                                        {title?.transaction?.deadline ? (
                                            <FormattedMessage {...formMessages.alert_activated} />
                                        ) : (
                                            <FormattedMessage {...formMessages.alert_deactivated} />
                                        )}
                                    </div>
                                </Col>
                                <Col xs={24} md={12}>
                                    <h1 className="title title-1 smaller mb-1">
                                        <FormattedMessage {...formMessages.user_and_vehicle} />
                                    </h1>
                                    <div className="user">
                                        {title?.handler?.firstName} {title?.handler?.name}
                                    </div>
                                    <div className="info-row">
                                        <span className="label text-primary">
                                            <FormattedMessage {...formMessages.card_number_label} />
                                        </span>
                                        &nbsp;{title?.titleNumber}
                                    </div>
                                    <div className="info-row">
                                        <span className="label text-primary">
                                            <FormattedMessage {...formMessages.vehicles_label} />
                                        </span>
                                        &nbsp;{title?.licensePlates?.join(', ')}
                                    </div>
                                </Col>
                            </Row>

                            <h1 className="title title-1 smaller mb-1 mt-2">
                                <FormattedMessage {...formMessages.addresses_billing_delivery} />
                            </h1>
                            <Row gutter={[15, 15]}>
                                {title?.transaction?.billingAddress && (
                                    <Col xs={24} md={12}>
                                        <div className="info-row">
                                            <span className="label text-primary">
                                                <FormattedMessage {...formMessages.billing} />
                                            </span>
                                        </div>
                                        {title.transaction.billingAddress.firstName}{' '}
                                        {title.transaction.billingAddress.lastName}
                                        <br />
                                        {title.transaction.billingAddress.street}
                                        {title.transaction.billingAddress.complement && (
                                            <>
                                                <br />
                                                {title.transaction.billingAddress.complement}
                                            </>
                                        )}
                                        <br />
                                        {title.transaction.billingAddress.zipCode}{' '}
                                        {title.transaction.billingAddress.city}
                                        <br />
                                        {title.transaction.billingAddress.country}
                                    </Col>
                                )}
                                {title?.transaction?.deliveryAddress && (
                                    <Col xs={24} md={12}>
                                        <div className="info-row">
                                            <span className="label text-primary">
                                                <FormattedMessage {...formMessages.delivery} />
                                            </span>
                                        </div>
                                        {title.transaction.deliveryAddress.firstName}{' '}
                                        {title.transaction.deliveryAddress.lastName}
                                        <br />
                                        {title.transaction.deliveryAddress.street}
                                        {title.transaction.deliveryAddress.complement && (
                                            <>
                                                <br />
                                                {title.transaction.deliveryAddress.complement}
                                            </>
                                        )}
                                        <br />
                                        {title.transaction.deliveryAddress.zipCode}{' '}
                                        {title.transaction.deliveryAddress.city}
                                        <br />
                                        {title.transaction.deliveryAddress.country}
                                    </Col>
                                )}
                            </Row>

                            <Divider />

                            <h1 className="title title-1 smaller mb-1">
                                <FormattedMessage {...formMessages.payment_summary} />
                            </h1>

                            <div className="price-row">
                                <div className="price-label">
                                    <FormattedMessage {...formMessages.total_without_vat} />
                                </div>
                                <div className="price">
                                    <Price value={totalPriceDutyFree} />
                                </div>
                            </div>
                            {renewPrice?.price.dutiesAmount.map((duty, index) => (
                                <div className="price-row" key={'duty-' + index.toString()}>
                                    <div className="price-label">
                                        <FormattedMessage
                                            {...formMessages.total_vat}
                                            values={{ name: duty.name, rate: duty.rate }}
                                        />
                                    </div>
                                    <div className="price">
                                        <Price value={duty.amount} />
                                    </div>
                                </div>
                            ))}

                            <Divider />

                            <div className="price-row larger">
                                <div className="price-label">
                                    <FormattedMessage {...formMessages.total_to_pay} />
                                </div>
                                <div className="price text-primary">
                                    <Price value={totalPrice} />
                                </div>
                            </div>
                        </div>

                        <div className="switch-row mt-2 text-left">
                            <Form.Item
                                name="acceptCgv"
                                rules={[
                                    { required: true },
                                    {
                                        type: 'boolean',
                                        message: formatMessage(formMessages.error_required),
                                        validator: async (_, value) => {
                                            if (value) {
                                                return await Promise.resolve();
                                            }
                                            return await Promise.reject(new Error('This field must be checked'));
                                        },
                                    },
                                ]}
                                className="mt-0 mb-0"
                            >
                                <Switch className="mb-0" disabled={isWaitingForPayment || authNmiState.loading} />
                            </Form.Item>
                            <div
                                className="label"
                                dangerouslySetInnerHTML={{
                                    __html: formatMessage(formMessages.accept_cgv, {
                                        cgv:
                                            '<a target="_blank" href="' +
                                            getRoute(RoutePathName.legals, { slug: 'cgv' }) +
                                            '">' +
                                            formatMessage(formMessages.cgv) +
                                            '</a>',
                                    }),
                                }}
                            />
                        </div>
                        {error ? (
                            <div className="login-error-message">
                                <Alert type="error" message={<FormattedMessage {...error} />} showIcon />
                            </div>
                        ) : null}
                        <div className="text-center mt-3 mb-3">
                            <Button
                                type="primary"
                                size="large"
                                shape="round"
                                className="btn-xl"
                                loading={isWaitingForPayment || authNmiState.loading}
                                htmlType="submit"
                            >
                                <FormattedMessage
                                    {...accountMessages.renew_and_pay}
                                    values={{ price: <Price value={totalPrice} /> }}
                                />
                            </Button>
                        </div>
                    </Form>
                    <Modal3DSecure isVisible={modal3DSecureVisible} onClose={close3DSecureModal} />
                </div>
            )}
        </>
    );
};

export default RenewSubscription;
